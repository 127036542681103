@import "src/theme/colors.scss";
@import "src/theme/media.scss";

.announcement {
  cursor: pointer;
  background-color: $light-primary-1;
  padding: 15px;

  @media #{$responsive} {
    font-size: 12px !important;
  }
}

.container {
  width: 100vw;
  z-index: 999;
}

.wrapper {
  position: absolute;
  width: 100vw;
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
  padding: 20px 30px 0;

  @media #{$mobile} {
    padding: 15px 10px;
  }

  @media #{$tablet} {
    padding: 15px 25px;
  }
}

.cta {
  @media #{$tablet} {
    padding: 10px !important;
    width: 50%;
  }

  @media #{$mobile} {
    padding: 10px !important;
    width: 100%;
  }
}

.options {
  align-items: center;
  display: flex;
  gap: 30px;

  @media #{$responsive} {
    gap: 15px;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
  }

  @media #{$tablet} {
    padding-left: 42px;
    padding-top: 45px;
  }

  @media #{$mobile} {
    padding-top: 45px;
  }
}

.section {
  align-items: center;
  gap: 70px;
  display: flex;

  @media #{$tablet} {
    align-items: flex-start;
    flex-direction: column;
    gap: 45px;
    width: 100%;
  }

  @media #{$mobile} {
    align-items: flex-start;
    flex-direction: column;
    gap: 45px;
    width: 100%;
  }
}

.navbarHeader {
  align-items: center;
  display: flex;

  @media #{$responsive} {
    justify-content: space-between;
    width: 100%;
  }
}

.openNavigation {
  font-size: 20px;
  color: $primary-variant-1;
}

.navbar {
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
  background-color: $white;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;

  padding: 30px 40px;
  width: 100%;

  @media #{$responsive} {
    overflow: hidden;
    flex-direction: column;
    padding: 25px;
  }
}

.logo {
  cursor: pointer;
  width: 180px;

  @media #{$tablet} {
    width: 120px;
  }

  @media #{$mobile} {
    width: 120px;
  }
}
