@import "src/theme/colors.scss";
@import "src/theme/media.scss";

.menu {
  position: relative;
}

.selectedOption {
  & * {
    color: $accent !important;
  }
}

.menuOption {
  & * {
    white-space: nowrap !important;
  }

  padding: 15px 30px;

  &:first-child {
    padding-top: 30px;

    @media #{$responsive} {
      padding-top: unset;
    }
  }

  &:last-child {
    padding-bottom: 30px;

    @media #{$responsive} {
      padding-bottom: unset;
    }
  }

  @media #{$responsive} {
    padding: unset;
    margin-top: 15px;
  }
}

.dropdown {
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate(-30px, 100%);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  border-radius: 10px;
  background-color: $white;
  min-width: 200px;

  @media #{$responsive} {
    position: relative;
    bottom: unset;
    left: unset;
    transform: unset;
    box-shadow: unset;
  }
}

.header {
  align-items: center;
  gap: 5px;
  display: flex;
  cursor: pointer;

  & * {
    user-select: none;
    color: $supplemental-variant-1;
    font-size: 12px;
  }
}
